import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, registerWithEmailAndPassword, signInWithGoogle } from "../Backend/firebase";
import { FaGoogle } from "react-icons/fa"; 
import { IoCloseCircleOutline } from "react-icons/io5";
import "./Register.css";
import { TextArea, Box, Card, Flex, Button, Grid, Link, DropdownMenu, Em, Checkbox, Separator, Callout } from '@radix-ui/themes';


// Register Function
function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const register = () => {
    if (!name) alert("Please enter name");
    registerWithEmailAndPassword(name, email, password);
  };
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/jobs");
  }, [user, loading]);
  return (
    <div className="register">
      <div className="register__container">
        <div className="register__close" onClick={() => navigate("/")}><IoCloseCircleOutline size={30}/></div>
        <input
          type="text"
          className="register__textBox"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Full Name"
        />
        <input
          type="text"
          className="register__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="register__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <Grid row="2" gap="3">
          <Button 
          color="green" 
          onClick={register}
          >
            Sign Up
          </Button>
          <Button
            onClick={signInWithGoogle}
          >
            <FaGoogle /> Sign up with  Google
          </Button>
        </Grid>
        <div>
        <Em><Link href="/login">Old here? Log in </Link></Em>
        </div>
      </div>
    </div>
  );
}
export default Register;