import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./Jobs.css";
import { auth, logout, } from "../Backend/firebase";
import { IoMenu, IoHome, IoPersonCircle } from "react-icons/io5";
import { MagnifyingGlassIcon } from '@radix-ui/react-icons'
import { Flex, Button, Grid, TextField, Separator, Callout } from '@radix-ui/themes';
import JobCard from "../Components/JobCard";
import { PiLightningFill } from "react-icons/pi";


function Jobs() {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [jobResults, setJobResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate('/login');
   
    }
    
    
  }, [user, navigate]);

  const mainSearch = async () => {
    setIsLoading(true);
    console.log('Searching for:', searchTerm);
    // Replace spaces with & for tsquery compatibility
    const formattedSearchTerm = searchTerm.replace(/\s+/g, ' & ');
    const response = await fetch(`https://api.oneclickapply.co/api/v1/jobs?search_term=${formattedSearchTerm}&column=title&limit=35`);
    const data = await response.json();
    console.log('loading...')
    if (response.status !== 200) {
      console.error('Error searching:', data);
      setIsLoading(false);
    } else {
      console.log(data);
      setJobResults(data.jobs);
      setIsLoading(false);
    }
    console.log(`end of search`);
  }

  useEffect(() => {
    // Function to handle click events on the window
    const handleWindowClick = (event) => {
        // Check if sidebar is open and if the click target is not the menu button
        if (sidebarOpen && !event.target.closest('.menu')) {
            setSidebarOpen(false);
        }
    };
    // Add event listener when the component is mounted
    window.addEventListener('click', handleWindowClick);
    // Remove event listener on cleanup
    return () => window.removeEventListener('click', handleWindowClick);
}, [sidebarOpen]); // Only re-run the effect if sidebarOpen changes
  

const handleLogout = () => {
  logout()
}

const toggleSidebar = () => {
  setSidebarOpen(!sidebarOpen);
}


  return (
    <div className="dashboard">
      <div className="header">
        {!user ? null :
            <Flex gap='3' justify='between' style={{padding: '20px'}}>
              <div className="menu" onClick={toggleSidebar}>
                <IoMenu size={30} />
              </div>
    
              <Button color="gray" onClick={handleLogout}>
                Logout
              </Button>
            </Flex>

        }

          <div className={sidebarOpen ? "sidebar open" : "sidebar"}>
            <Link to="/dashboard"><div className="menu-item"><IoHome size={30}/></div></Link>
            <Link to="/personal-information"><div className="menu-item"><IoPersonCircle size={30} /></div></Link>
            <Link to="/jobs"><div className="menu-item"><PiLightningFill size={30}/></div></Link>
          </div>
      </div>
      {!user ? null :
      <div className="jobcontent">
        <Grid row="3" gap="3" width="auto">
          <Flex gap='2'className="SearchRow">
            <TextField.Root size="3">
              <TextField.Slot>
                <MagnifyingGlassIcon height="16" width="16" />
              </TextField.Slot>
              <TextField.Input 
                size="3" 
                placeholder="Find your job..." 
                value={searchTerm} 
                onChange={(e) => setSearchTerm(e.target.value)} 
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    mainSearch();
                  }
                }}
              />
            </TextField.Root>
            <Button onClick={mainSearch} variant="classic" size='3'>Find Job</Button>
          </Flex>
          {/* <Flex gap="3" className="FilterRow">
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <Button variant="soft">
                  Job Type
                  <CaretDownIcon />
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                <Text as="label" size="2">
                  <Flex gap="2">
                    <Checkbox/> Full Time
                  </Flex>
                </Text>
                <DropdownMenu.Separator />
                <Text as="label" size="2">
                  <Flex gap="2">
                    <Checkbox/> Internship
                  </Flex>
                </Text>
                </DropdownMenu.Content>
            </DropdownMenu.Root>
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <Button variant="soft">
                  Location
                  <CaretDownIcon />
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                <Text as="label" size="2">
                  <Flex gap="2">
                    <Checkbox/> Location 1
                  </Flex>
                </Text>
                <DropdownMenu.Separator />
                <Text as="label" size="2">
                  <Flex gap="2">
                    <Checkbox/> Location 2
                  </Flex>
                </Text>
                </DropdownMenu.Content>
            </DropdownMenu.Root>
          </Flex> */}
          <Separator my="3" size="4" />
          <Grid columns="3" gap="3" width="auto">
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                jobResults.length > 0 ? (
                  jobResults.map(job => (
                    <Flex className="JobCardsRow">
                      <JobCard key={job.id} jobTitle={job.title} location={job.location} company={job.company} url={job.absolute_url} description={job.description}/>
                    </Flex>
                  ))
                ) : (
                  <Callout.Root color="blue">
                    <Callout.Text>
                    Search for a job!
                    </Callout.Text>
                  </Callout.Root>
                )
              )}
            
          </Grid>
          
        </Grid>
      </div>
      
      
    }
    </div>
  );
}

export default Jobs;
