import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { usePostHog } from 'posthog-js/react'
import "./Dashboard.css";
import { auth, logout,readUserData } from "../Backend/firebase";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { IoMenu, IoHome, IoPersonCircle } from "react-icons/io5";
import { PiLightningFill } from "react-icons/pi";
import { Grid, Button, Flex } from '@radix-ui/themes';
import { RocketIcon } from '@radix-ui/react-icons'
import toast, { Toaster } from 'react-hot-toast';


function Dashboard() {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const [user] = useAuthState(auth);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [jobLink, setJobLink] = useState('');
  const [applicationType, setApplicationType] = useState('');

  useEffect(() => {
    if (!user) {
      navigate('/login');
   
    }
    
    
  }, [user, navigate]);
  
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  useEffect(() => {
    // Function to handle click events on the window
    const handleWindowClick = (event) => {
        // Check if sidebar is open and if the click target is not the menu button
        if (sidebarOpen && !event.target.closest('.menu')) {
            setSidebarOpen(false);
        }
    };

    // Add event listener when the component is mounted
    window.addEventListener('click', handleWindowClick);

    // Remove event listener on cleanup
    return () => window.removeEventListener('click', handleWindowClick);
}, [sidebarOpen]); // Only re-run the effect if sidebarOpen changes
  
const isGreenhouseLink = (link) => {
  const greenhousePattern = /^https?:\/\/.*greenhouse\.io\/.*$/;
  return greenhousePattern.test(link);
};


const submitApplication = async () => {
  posthog?.capture('Job Application Submitted using Paste');

  if (!isGreenhouseLink(jobLink)) {
    toast.error('We only accept jobs posted on the Greenhouse job board for now.');
    return;
  }


  toast.success('Application Submitted!');
  try {
    const personalInfo = await readUserData(); // Fetch personal info from Firebase
    // console.log(personalInfo);
    const response = await fetch('https://oneclick-backend.vercel.app/add_to_jobqueue', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        job_link: jobLink,
        personal_info: personalInfo,
        user_id: user.uid,
        user_email: user.email,
      }),
    });

  } catch (error) {
    console.log('Error submitting application:', error);
  }
  
  setJobLink('');
};
const handleLogout = () => {
  logout()
}



  return (
    <div className="dashboard">
      {!user ? null :
        <Flex gap='3' justify='between' style={{padding: '20px'}}>
          <div className="menu" onClick={toggleSidebar}>
            <IoMenu size={30} />
          </div>

          <Button color="gray" onClick={handleLogout}>
            Logout
          </Button>
        </Flex>
        
      }

      <div className={sidebarOpen ? "sidebar open" : "sidebar"}>
        <Link to="/dashboard"><div className="menu-item"><IoHome size={30}/></div></Link>
        <Link to="/personal-information"><div className="menu-item"><IoPersonCircle size={30} /></div></Link>
        <Link to="/jobs"><div className="menu-item"><PiLightningFill size={30}/></div></Link>

      </div>
      {!user ? null :
      <div className="job-application">
        <Toaster />
        <h2>Enter Application Link</h2>
        <select 
          value={applicationType}
          onChange={(e) => setApplicationType(e.target.value)}
          className="application-type-select"
        >
          <option value="">Select Application Type</option>
          <option value="Greenhouse">Greenhouse Application</option>
        </select>
        <input 
          type="text" 
          placeholder="Enter job link..." 
          value={jobLink}
          onChange={(e) => setJobLink(e.target.value)}
          className="job-link-input"
        />
        {jobLink && (
          <Button color="green" className="apply-button" onClick={submitApplication}>
            <RocketIcon width='16' height='16' />
            Apply
            </Button>
            
        )}

      <div className="feedback-button" >
      <Flex gap="2">
        <Button onClick={() => window.open('https://forms.gle/A2S3rQCvR5Sqyes19', '_blank')}>Request New Job Board</Button>
        <Button onClick={() => window.open('https://forms.gle/x1whyAmf1APVmGbT9', '_blank')}>Any Feedback for us?</Button>
      </Flex>
      </div>
      </div>
      
      
    }
    </div>
  );
}

export default Dashboard;