import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import '@radix-ui/themes/styles.css';
import { Theme } from '@radix-ui/themes';
import { PostHogProvider} from 'posthog-js/react'

const options = {
  api_host: 'https://app.posthog.com',
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Analytics />
      <PostHogProvider apiKey={'phc_22mSYX3rZNbfDmiHzlUEZW2LI1djyozXHrSb6OAl1Vm'} options={options}>
        <Theme>
          <App />
        </Theme>
      </PostHogProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
