import React, { useState } from "react";
import { usePostHog } from 'posthog-js/react'
import { Card, Flex, Button, Box, Text, Dialog, ScrollArea, Kbd, Callout } from '@radix-ui/themes';
import { ArrowTopRightIcon, InfoCircledIcon } from '@radix-ui/react-icons'
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logout,readUserData } from "../Backend/firebase";
import parser from 'html-react-parser';
import toast, { Toaster } from 'react-hot-toast';



function JobCard({ jobTitle, location, company, url, description }) {

    const posthog = usePostHog();
    const [user] = useAuthState(auth);
    const [ApplyButton, setApplyButton] = useState('Apply');
    const notify = () => toast.success('Application Submitted!');

    const submitApplication = async (JobLink) => {
        posthog?.capture('Job Application Submitted w JobBoard');
        notify();
        setApplyButton('Applied!');
        try {
          const personalInfo = await readUserData(); // Fetch personal info from Firebase
          // console.log(personalInfo);
          const response = await fetch('https://oneclick-backend.vercel.app/add_to_jobqueue', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              job_link: JobLink,
              personal_info: personalInfo,
              user_id: user.uid,
              user_email: user.email,
            }),
          });
      
        } catch (error) {
          console.log('Error submitting application:', error);
        }
        
      };
    

  return (
    <Card variant= 'surface' style={{ minHeight: 180, maxHeight: 180, minWidth: 240, maxWidth: 240 }}>
      <Flex gap="3" align="start" direction='column' style={{ height: '100%', justifyContent: 'space-between' }}>
            <Box>
              <Text as="div" size="2" weight="bold">
                {jobTitle}
              </Text>
              <Text as="div" size="2" color="gray" style={{paddingTop: '8px', paddingRight: '10px'}}>
                {company}
              </Text>
              <Text as="div" size="2" color="gray" style={{paddingBottom: '8px'}}>
                {location}
              </Text>
            </Box>
            <Flex gap="3" direction='row' width='100%'>
                <Button onClick={() => submitApplication(url)} variant='solid' color='green' width='100%'> 
                    {ApplyButton}

                </Button>
                <Toaster />
                
              
                <Dialog.Root>
                  <Dialog.Trigger>
                  <Button variant='soft' color='blue'>
                        Details
                    </Button>
                  </Dialog.Trigger>

                  <Dialog.Content style={{ maxWidth: 500 }}>
                    <Dialog.Title>Job Details</Dialog.Title>
                    <ScrollArea style={{ height: 500 }}>
                        <Box p="2" pr="8">
                        <iframe srcDoc={parser(description)} style={{ width: '110%', height:450 }}></iframe>
                        <a href={url} target="_blank" rel="noreferrer">
                            <Text size="4">
                            <Button variant='soft' color='jade' >View Job<ArrowTopRightIcon /></Button>
                            </Text>
                        </a>
                        </Box>
                    </ScrollArea>

                    <Flex gap="3" mt="4" justify="end">
                      <Dialog.Close>
                        <Button variant="soft" color="gray">
                          Cancel
                        </Button>
                      </Dialog.Close>
                    </Flex>
                  </Dialog.Content>
                </Dialog.Root>
            </Flex>
      </Flex>
    </Card>
  );
}

export default JobCard;
