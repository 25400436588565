import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, writeUserData, readUserData } from "../Backend/firebase";
import { useAuthState } from "react-firebase-hooks/auth";


import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
registerCoreBlocks();



function WelcomeForm() {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        race: '',
        gender: '',
        lgbtq_affliation: '',
        disability: '',
        resume_text: '',
        custom_info: 'Custom info like when you graduate, if you need sponsorship, etc'
    });

    useEffect(() => {
      if (!user) {
        navigate('/login');
     
      }
      
      
    }, [user, navigate]);
  

    const SubmitForm = () => {
        writeUserData(
            userInfo.first_name,
            userInfo.last_name,
            userInfo.email,
            userInfo.phone,
            userInfo.resume_text,
            userInfo.race, // You might want to keep these if they are part of the personal info
            userInfo.gender,
            userInfo.lgbtq_affliation,
            userInfo.disability,
            userInfo.custom_info
            

        ).then(() => {
            console.log('Personal and Document information saved successfully!');
            
        }).catch(error => {
            console.error(error);
        });
    };

return (
    <div style={{ width: "100%", height: "100vh" }}>
    <Form
      formId="personal-info-form"
      formObj={{
        blocks: [
          {
              name: "welcome-screen",
              id: "jg1401r",
              attributes: {
                label: "Welcome to OneClick Apply!",
                description: "We know we said one click apply, but honestly this is the only time you would put in your information.",
                attachment: {
                  type: "image",
                  url:
                    "/oneclicklogo.png"
                }
              }
          },
          {
            name: "short-text",
            id: "first_name",
            attributes: {
              label: "First Name",
              required: true,
              defaultValue: userInfo.first_name
            }
          },
          {
            name: "short-text",
            id: "last_name",
            attributes: {
              label: "Last Name",
              required: true,
              defaultValue: userInfo.last_name
            }
          },
          {
            name: "email",
            id: "email",
            attributes: {
              label: "The email you use for job applications",
              required: true,
              defaultValue: userInfo.email
            }
          },
          {
            name: "number",
            id: "phone",
            attributes: {
              label: "The phone no. you use for job applications",
              required: true,
              defaultValue: userInfo.phone
            }
          },
          {
            name: "long-text",
            id: "resume_text",
            attributes: {
              label: "Paste your resume here",
              required: false,
              defaultValue: userInfo.resume_text
            }
          },
          {
            name: "long-text",
            id: "custom_info",
            attributes: {
              label: "Custom Information",
              required: false,
              defaultValue: userInfo.custom_info
            }
          },
          {
            name: "dropdown",
            id: "race",
            attributes: {
              label: "Race or Ethnicity",
              required: true,
              choices: [
                { label: "Asian", value: "Asian" },
                { label: "Black or African American", value: "Black or African American" },
                { label: "Hispanic or Latino", value: "Hispanic or Latino" },
                { label: "White", value: "White" },
                { label: "Two or More Races", value: "Two or More Races" },
                { label: "Other", value: "Other" }
              ],
              defaultValue: userInfo.race
            }
          },
          {
            name: "dropdown",
            id: "gender",
            attributes: {
              label: "Gender",
              required: true,
              choices: [
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
                { label: "Non-Binary/Third Gender", value: "Non-Binary/Third Gender" },
                { label: "Prefer Not to Say", value: "Prefer Not to Say" },
                { label: "Other", value: "Other" }
              ],
              defaultValue: userInfo.gender
            }
          },
          {
            name: "dropdown",
            id: "lgbtq_affiliation",
            attributes: {
              label: "LGBTQIA+ Affiliation",
              required: true,
              choices: [
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
                { label: "Prefer Not to Say", value: "Prefer Not to Say" }
              ],
              defaultValue: userInfo.lgbtq_affliation
            }
          },
          {
            name: "dropdown",
            id: "disability",
            attributes: {
              label: "Any Disabilities",
              required: true,
              choices: [
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
                { label: "Prefer Not to Say", value: "Prefer Not to Say" }
              ],
              defaultValue: userInfo.disability
            }
          }
        ],
      }}
      onSubmit={(data, { completeForm, setIsSubmitting, goToBlock, setSubmissionErr }) => {
        setTimeout(() => {
            navigate('/jobs');
          setIsSubmitting(false);


          const formAnswers = {
            first_name: data.answers.first_name?.value,
            last_name: data.answers.last_name?.value,
            email: data.answers.email?.value,
            phone: data.answers.phone?.value,
            race: data.answers.race?.value,
            gender: data.answers.gender?.value,
            lgbtq_affiliation: data.answers.lgbtq_affiliation?.value,
            disability: data.answers.disability?.value,
            resume_text: data.answers.resume_text?.value,
            custom_info: data.answers.custom_info?.value
          };

          // Set all the values to this.
          userInfo.first_name = data.answers.first_name?.value;
          userInfo.last_name = data.answers.last_name?.value;
          userInfo.email = data.answers.email?.value;
          userInfo.phone = data.answers.phone?.value;
          userInfo.race = data.answers.race?.value;
          userInfo.gender = data.answers.gender?.value;
          userInfo.lgbtq_affliation = data.answers.lgbtq_affiliation?.value;
          userInfo.disability = data.answers.disability?.value;
          userInfo.resume_text = data.answers.resume_text?.value;
          userInfo.custom_info = data.answers.custom_info?.value;
        
          
          console.log(`Quill Form Results`,formAnswers);
          SubmitForm()

          completeForm();
        }, 500);
      }}
    />
  </div>
)
    }

    export default WelcomeForm;
