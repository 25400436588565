import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { 
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut
} from "firebase/auth";
import { 
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc
} from "firebase/firestore";
import {getDatabase, ref, set, child, get} from "firebase/database";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDyYH8AEDqkzB_LqrVS_sJW53tXGV56IUQ",
  authDomain: "oneclickapply-13a44.firebaseapp.com",
  projectId: "oneclickapply-13a44",
  storageBucket: "oneclickapply-13a44.appspot.com",
  messagingSenderId: "138007512475",
  appId: "1:138007512475:web:67c308fffb062390fe8d3e",
  measurementId: "G-Z9KKDD0GVP",
  databaseURL: "https://oneclickapply-13a44-default-rtdb.firebaseio.com/"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);



// Sign in with google
const googleProvider = new GoogleAuthProvider();
export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

// Log in with Email and Password
export const logInWithEmailAndPassword = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

// Register with Email and Password
export const registerWithEmailAndPassword = async (name, email, password) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const user = res.user;
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name,
        authProvider: "local",
        email,
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

// Password Reset
export const sendPasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset link sent!");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

// Logout
export const logout = () => {
    signOut(auth);
  };

// Database Shenainigans

export function writeUserData(first_name, last_name, email, phone, resume_text, race, gender, lgbtq_affliation, disability, custom_info) {
  return new Promise((resolve, reject) => {
    const user = auth.currentUser;
    if (user) {
      const database = getDatabase();
      const reference = ref(database, 'users/' + user.uid);

      set(reference, {
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone,
        resume_text: resume_text,
        race: race,
        gender: gender,
        lgbtq_affliation: lgbtq_affliation,
        disability: disability,
        custom_info: custom_info
      }).then(() => resolve()).catch((error) => reject(error));
    } else {
      reject("No user is signed in.");
    }
  });
}
export const readUserData = () => {
  return new Promise((resolve, reject) => {
    const user = auth.currentUser;
    if (user) {
      const database = getDatabase();
      const reference = ref(database);
      const userId = user.uid;

      get(child(reference, `users/${userId}`)).then((snapshot) => {
        if (snapshot.exists()) {
          resolve(snapshot.val());
        } else {
          resolve(null); // No data available
        }
      }).catch((error) => {
        reject(error);
      });
    } else {
      reject("No user is signed in.");
    }
  });
};


export const getUserEmail = (user_id) => {
  return new Promise((resolve, reject) => {
    const database = getDatabase();
    const reference = ref(database, 'users/' + user_id);

    get(child(reference, 'email')).then((snapshot) => {
      if (snapshot.exists()) {
        resolve(snapshot.val());
      } else {
        resolve(null); // No email available
      }
    }).catch((error) => {
      reject(error);
    });
  });
}





