import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, writeUserData, readUserData, logout } from "../Backend/firebase";
import "./PersonalInformation.css";
import { Link } from "react-router-dom";
import { IoMenu, IoHome, IoPersonCircle } from "react-icons/io5";
import { PiLightningFill } from "react-icons/pi";
import { Button, Flex } from '@radix-ui/themes';



function PersonalInformation() {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        race: '',
        gender: '',
        lgbtq_affliation: '',
        disability: '',
        resume_text: '',
        custom_info: 'Custom info like when you graduate, if you need sponsorship, etc'
    });
    const [EditMode, setEditMode] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);

     useEffect(() => {
    if (!user) {
      navigate('/login');
   
    }
    
    
  }, [user, navigate]);

    useEffect(() => {
        if (loading) return;
    
        readUserData().then(data => {
            if (data) {
                setUserInfo({
                    first_name: data.first_name || '',
                    last_name: data.last_name || '',
                    email: data.email || '',
                    phone: data.phone || '',
                    race: data.race || '',
                    gender: data.gender || '',
                    lgbtq_affliation: data.lgbtq_affliation || '',
                    disability: data.disability || '',
                    resume_text: data.resume_text || 'Paste your resume here',
                    custom_info: data.custom_info || 'Custom info like when you graduate, if you need sponsorship, etc',
                    
                });
            }
        }).catch(error => {
            console.error(error);
        });
    }, [user, loading, navigate]);




    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserInfo(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const toggleEditMode = () => {
        setEditMode(!EditMode);
    };


    const handleInfoSubmit = () => {
        writeUserData(
            userInfo.first_name,
            userInfo.last_name,
            userInfo.email,
            userInfo.phone,
            userInfo.resume_text,
            userInfo.race, // You might want to keep these if they are part of the personal info
            userInfo.gender,
            userInfo.lgbtq_affliation,
            userInfo.disability,
            userInfo.custom_info


        ).then(() => {
            alert('Personal and Document information saved successfully!');
            toggleEditMode(); // Exit personal edit mode after saving
        }).catch(error => {
            console.error(error);
            alert('Failed to save personal information.');
        });
    };



    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    useEffect(() => {
        // Function to handle click events on the window
        const handleWindowClick = (event) => {
            // Check if sidebar is open and if the click target is not the menu button
            if (sidebarOpen && !event.target.closest('.menu')) {
                setSidebarOpen(false);
            }
        };

        // Add event listener when the component is mounted
        window.addEventListener('click', handleWindowClick);

        // Remove event listener on cleanup
        return () => window.removeEventListener('click', handleWindowClick);
    }, [sidebarOpen]); // Only re-run the effect if sidebarOpen changes

    const handleLogout = () => {
        logout()
      }
      

    return (
        <div>
        {!user ? null :
        (
        <div className='container'>
        <Flex gap='3' justify='between' style={{padding: '20px'}}>
          <div className="menu" onClick={toggleSidebar}>
            <IoMenu size={30} />
          </div>

          <Button color="gray" onClick={handleLogout}>
            Logout
          </Button>
        </Flex>

            <div className={sidebarOpen ? "sidebar open" : "sidebar"}>
                <Link to="/dashboard"><div className="menu-item"><IoHome size={30}/></div></Link>
                <Link to="/personal-information"><div className="menu-item"><IoPersonCircle size={30}/></div></Link>
                <Link to="/jobs"><div className="menu-item"><PiLightningFill size={30}/></div></Link>
            </div>
            <div className="section personal-info">
                <div className='text_container'>
                    <h1>Application Information</h1>
                    <p>Feel free to edit any information. Use custom info to add any other relevant information.</p>
                </div>
             <div className='form-container'>
                <div className="info-block">
                    <h2>Personal Info</h2>
                    {EditMode ? (
                        <>
                            <div>
                                <label>First Name:</label>
                                <input type="text" name="first_name" value={userInfo.first_name} onChange={handleInputChange} />
                            </div>
                            <div>
                                <label>Last Name:</label>
                                <input type="text" name="last_name" value={userInfo.last_name} onChange={handleInputChange} />
                            </div>
                            <div>
                                <label>Email:</label>
                                <input type="email" name="email" value={userInfo.email} onChange={handleInputChange} />
                            </div>
                            <div>
                                <label>Phone:</label>
                                <input type="text" name="phone" value={userInfo.phone} onChange={handleInputChange} />
                            </div>
                        </>
                    ) : (
                        <>
                            <p><strong>First Name:</strong> {userInfo.first_name}</p>
                            <p><strong>Last Name:</strong> {userInfo.last_name}</p>
                            <p><strong>Email:</strong> {userInfo.email}</p>
                            <p><strong>Phone:</strong> {userInfo.phone}</p>
                        </>
                    )}
                </div>

                <div className="section document-block">
                    <h2>Documents</h2>
                    <div>
                        <label htmlFor="resume">Resume:</label>
                        <textarea id="resume_text" name="resume_text" onChange={handleInputChange} value={userInfo.resume_text}></textarea>
                    </div>
                    <div>
                        <label htmlFor="custom_info">Custom Info:</label>
                        <textarea id="custom_info" name="custom_info" onChange={handleInputChange} value={userInfo.custom_info}></textarea>
                    </div>
                </div>

                <div className="section identity-block">
                    <h2>Voluntary Self Identification</h2>
                    {EditMode ? (
                        <>
                            <div>
                                <label>Race or Ethnicity:</label>
                                <select name="race" value={userInfo.race} onChange={handleInputChange}>
                                    <option value="">Please select</option>
                                    <option value="Asian">Asian</option>
                                    <option value="Black or African American">Black or African American</option>
                                    <option value="Hispanic or Latino">Hispanic or Latino</option>
                                    <option value="White">White</option>
                                    <option value="Two or More Races">Two or More Races</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div>
                                <label>Gender:</label>
                                <select name="gender" value={userInfo.gender} onChange={handleInputChange}>
                                    <option value="">Please select</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Non-Binary/Third Gender">Non-Binary/Third Gender</option>
                                    <option value="Prefer Not to Say">Prefer Not to Say</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div>
                                <label>LGBTQIA+ Affiliation:</label>
                                <select name="lgbtq_affliation" value={userInfo.lgbtq_affliation} onChange={handleInputChange}>
                                    <option value="">Please select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                    <option value="Prefer Not to Say">Prefer Not to Say</option>
                                </select>
                            </div>
                            <div>
                                <label>Any Disabilities:</label>
                                <select name="disability" value={userInfo.disability} onChange={handleInputChange}>
                                    <option value="">Please select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                    <option value="Prefer Not to Say">Prefer Not to Say</option>
                                </select>
                            </div>
                            <Button variant="soft" color="green" onClick={handleInfoSubmit}>Save</Button>
                        </>
                    ) : (
                        <>
                            <p><strong>Race:</strong> {userInfo.race}</p>
                            <p><strong>Gender:</strong> {userInfo.gender}</p>
                            <p><strong>LGBTQ Affiliation:</strong> {userInfo.lgbtq_affliation}</p>
                            <p><strong>Disability:</strong> {userInfo.disability}</p>
                            <Button variant="soft" onClick={toggleEditMode}>Edit</Button>
                        </>
                    )}
                </div>
                <div>
                </div>
            </div>
            </div>
        </div>
        )}
        </div>
    );
}

export default PersonalInformation;
