import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../Backend/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";
import { FaGoogle } from "react-icons/fa";
import { IoCloseCircleOutline } from "react-icons/io5";
import { TextArea, Box, Card, Flex, Button, Grid, Link, DropdownMenu, Em, Checkbox, Separator, Callout } from '@radix-ui/themes';

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/jobs");
  }, [user, loading]);
  return (
    <div className="login">
      <div className="login__container">
        <div className="login__close" onClick={() => navigate("/")}><IoCloseCircleOutline size={30}/></div>
        <input
          type="email"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <Grid row="2" gap="3">
          <Button
            className="login__btn"
            onClick={() => logInWithEmailAndPassword(email, password)}
            variant="solid"
            color="green"
          >
            Log in
          </Button>
          <Button 
            className="login__btn login__google" 
            onClick={signInWithGoogle} 
            variant="solid" 
            >
            <FaGoogle /> Log in with Google
          </Button>
        </Grid>
        <div>
          <Em><Link href="/register">New here? Register </Link></Em>
        </div>
      </div>
    </div>
    
  );
}
export default Login;